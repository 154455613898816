import React from 'react';

import { TagPicker } from '@fluentui/react/lib/Pickers';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import mockdata from "../../resources/mockchannels.json"

const rootClass = mergeStyles({
  maxWidth: 500,
});

const pickerSuggestionsProps = {
  suggestionsHeaderText: 'Gefundene Channels',
  noResultsFoundText: 'Keine Channels gefunden',
};

const channelTags = mockdata.d.results.map(item => ({ key: item.TechnicalId, name: item.TechnicalId }));
/*
let credentials = "b784d55f478c80749577c96c790e5";

fetch(properties.backendLink.baseUrl +  "/com.vantaio.cp.enterprisenewsmanager.backend/BackendService.svc/Channels?$format=json", {
    method: "GET",
    headers: {
        "Authorization": "BEARER " + credentials
    },
    credentials: 'include'
})
    .then(x => {
        console.log(x);
        this.setState({channels: "karsten"});
    })
    .catch(x => {
        console.log(x);
        this.setState({channels: "Nicht Karsten"});
    }
);
*/
/*
let that = this;
let xhr = new XMLHttpRequest();
xhr.open("POST", properties.backendLink + "/com.vantaio.cp.enterprisenewsmanager.backend/BackendService.svc/Channels?$format=json");
xhr.setRequestHeader("Authorization", "BEARER " + credentials);
xhr.onload = () => {that.setState({channels:xhr.response})};
xhr.onerror= () => {that.setState({channels:xhr.response})};
xhr.send();
*/

const listContainsTagList = (tag, tagList) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};

const filterSelectedTags = (filterText, tagList) => {
  return filterText ? channelTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) : [];
};

const getTextFromItem = (item) => item.name;

class ChannelPicker extends React.Component {
  // All pickers extend from BasePicker specifying the item type.
  constructor(props){
      super(props);
      this.props = props;
      console.log(this.props)

      this.onItemSelected = (item) => {
        if (listContainsTagList(item, this.items)) {
          return null;
        }
        return item;
      };
  }

  componentDidMount(){
      console.log(this.props)
  }

  render() {
    return (
        <div className={rootClass}>
        <TagPicker
            onResolveSuggestions={filterSelectedTags}
            onItemSelected={this.props.onItemSelected}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            onChange={this.props.onItemChange}
        />
        </div>
    );};
};

export default ChannelPicker;
