class destinationUrl{
    constructor(sUrl, channels = undefined, customQuery){
        this.url = sUrl;
        this.channels = channels;
        this.customQuery = customQuery;

        this.fullUrl = this.getUrl();
    }

    getUrl = () => {
        return this.url + (this.channels !== undefined ? "&channels=" + String(this.channels) : "")
             + (this.customQuery !== undefined ? "&customQuery=" + this.customQuery : "");
    }

    setChannels = (aChannels) => {
        this.channels = aChannels;
        this.fullUrl = this.getUrl();
    }

    addChannel = (sChannel) => {
        if(this.channels === undefined){
            this.channels = [];
        }
        this.channels.push(sChannel);
        this.fullUrl = this.getUrl();
    }

    getChannels = () => this.channels;

    getFullUrl = () => this.fullUrl;
}

const properties = {
    groupcardLink: new destinationUrl("https://nicoresearchgroupcard-kb6xraw1pv.dispatcher.hana.ondemand.com/?hc_reset", ["corporate_news", "it_news", "hr_news", "covid_19_news", "industry_news"]),
    enmLink: new destinationUrl("https://vtioenmui5-a8fee34ea.dispatcher.hana.ondemand.com/index.html?CockpitId=news_cockpit_intranet"),
    abocardLink: new destinationUrl("https://nicoresearchabocard-kb6xraw1pv.dispatcher.hana.ondemand.com/?hc_reset",  ["industry_news"]),
    backendLink: new destinationUrl("https://vtiomodulesjavaee7kb6xraw1pv.hana.ondemand.com")
}



export default {properties, destinationUrl};